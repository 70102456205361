.nf-tabs {
	&-control {
		display: flex;
		margin-bottom: 4px;
		@include media-breakpoint-down(xs) {
			display: none;
		}
		&__item {
			cursor: pointer;
			border-radius: 3px;
			padding: 10px 20px;
			font-size: custom(font_size);
			color: custom(font_color);
			background-color: custom(add-background_color, #ccc);
			border-radius: custom(border_radius);
			&.is-active {
				position: relative;
				border-bottom-left-radius: 0;
				border-bottom-right-radius: 0;
				background-color: custom(background_color);
				&:after {
					content: "";
					display: block;
					position: absolute;
					left: 0;
					right: 0;
					top: 100%;
					height: 4px;
					background-color: custom(background_color, currentColor);
				}
			}
			& + & {
				margin-left: 4px;
			}
		}
	}
	&__container {
		color: custom(font_color, $color-white);
		border-radius: 0 custom(border_radius, 3px) custom(border_radius, 3px) custom(border_radius, 3px);
		@include media-breakpoint-down(xs) {
			border-radius: custom(border_radius);
		}
		@include media-breakpoint-up(md) {
			background-color: custom(background_color);
		}
	}
	&-item {
		display: none;
		@include media-breakpoint-down(xs) {
			background-color: custom(add-background_color);
		}
		&-title {
			position: relative;
			padding: 12px;
			font-weight: 700;
			font-size: custom(font_size);
			color: custom(font_color);
			@include media-breakpoint-down(xs) {
				background-color: custom(background_color);
				&[data-id="booking"] {
					border-top-left-radius: custom(border_radius);
					border-top-right-radius: custom(border_radius);
				}
				&[data-id="status-booking"]:not(.is-active) {
					border-bottom-left-radius: custom(border_radius);
					border-bottom-right-radius: custom(border_radius);
				}
			}
			@include media-breakpoint-up(md) {
				display: none;
			}
			&:after {
				content: "";
				position: absolute;
				width: 12px;
				height: 12px;
				background: url("../images/arrow-down.png") center no-repeat;
				background-size: contain;
				top: 50%;
				transform: translateY(-50%);
				right: 12px;
				transition: 0.25s transform;
			}
			&.is-active {
				@include media-breakpoint-down(xs) {
					text-align: center;
					font-size: 20px;
					+ .nf-tabs-item[data-id="status-booking"] {
						border-bottom-left-radius: custom(border_radius);
						border-bottom-right-radius: custom(border_radius);
					}
				}
				&:after {
					transform: translateY(-50%) rotate(180deg);
				}
			}
		}
		+ .nf-tabs-item-title {
			border-top: 1px solid custom(font_color, $color-white);
		}
	}
	&-form {
		position: relative;
		width: 100%;
		flex: 0 0 100%;
		&__row {
			&--status {
				display: none !important;
				position: absolute;
				left: 0;
				right: 0;
				background-color: custom(background_color);
				margin-bottom: 0;
				align-items: center;
				top: -15px;
				bottom: -15px;
				border-radius: 0 custom(border_radius) custom(border_radius) custom(border_radius);
				@include media-breakpoint-down(xs) {
					left: -12px;
					right: -12px;
					padding: 15px 12px;
					border-radius: 0 0 custom(border_radius) custom(border_radius);
				}
				&.is-show {
					display: flex !important;
					@include media-breakpoint-down(xs) {
						display: block !important;
					}
				}
				&:after {
					cursor: pointer;
					content: "";
					display: block;
					position: absolute;
					top: 12px;
					right: 12px;
					width: 12px;
					height: 12px;
					background: url("../images/close.png") center no-repeat;
					background-size: contain;
				}
			}
		}
		&__group {
			position: relative;
			font-size: custom(font_size);
			color: custom(font_color);
			&--flex {
				display: flex;
				flex-wrap: wrap;
				align-items: center;
				justify-content: space-between;
			}
			& + & {
				margin-top: 15px;
			}
			select {
				@extend %custom-input;
				border-radius: custom(border_radius);
				option {
					background-color: custom(background_color);
				}
			}

			.select2-container--default {
				&,
				&.select2-container--disabled {
					.select2-selection--single {
						@extend %custom-input;
						border-radius: custom(border_radius);
						.select2-selection__rendered {
							line-height: inherit;
							color: inherit;
							padding: 0;
						}
						.select2-selection__arrow {
							height: 41px;
							b {
								border-color: custom(font_color) transparent transparent transparent;
							}
						}
					}
				}
				&.select2-container--disabled {
					opacity: 0.5;
				}
			}

			input[type="text"],
			input[type="date"] {
				@extend %custom-input;
				border-radius: custom(border_radius);
				outline: none;
			}

			input[type="date"],
			input[type="time"] {
				@extend %custom-input;
				border-radius: custom(border_radius);
				position: relative;
				&::-webkit-calendar-picker-indicator {
					background: none;
					cursor: pointer;
					position: absolute;
					width: 100%;
					height: 100%;
					left: 0;
					top: 0;
					padding: 0;
					margin: 0;
				}
				&:focus {
					outline: none;
				}
			}

			input::placeholder {
				color: custom(font_color) !important;
			}

			table {
				border-collapse: collapse;
				line-height: 22.5px;
			}
		}
		&__checkbox {
			position: absolute;
			opacity: 0;
			visibility: hidden;
			pointer-events: none;
			&:checked {
				~ .nf-tabs-form__date .forTime {
					display: block;
				}
				+ .nf-tabs-form__label {
					&:before {
						transform: translate(-5px, -50%);
						background-color: custom(button_color);
					}
				}
			}
			+ .nf-tabs-form__label {
				cursor: pointer;
				position: relative;
				display: flex;
				align-items: center;
				&:before,
				&:after {
					content: "";
					display: block;
				}
				&:after {
					border-radius: 12px;
					height: 24px;
					width: 40px;
					flex: 0 0 40px;
					border: 1px solid custom(font_color);
					margin-left: 12px;
				}
				&:before {
					position: absolute;
					top: 50%;
					transform: translateY(-50%);
					background-color: custom(font_color);
					border-radius: 50%;
					box-shadow: 0 3px 8px rgb(0 0 0 / 15%), 0 3px 1px rgb(0 0 0 / 6%);
					width: 18px;
					height: 18px;
					right: 0;
					transform: translate(-19px, -50%);
					transition: transform 0.25s;
				}
			}
			~ .nf-tabs-form__date .forTime {
				display: none;
			}
		}
		&__label {
			display: block;
			margin: 0;
			font-size: custom(font_size);
			color: custom(font_color);
		}
		&-places {
			display: flex;
			align-items: center;
			position: relative;
			&__input {
				display: block;
				width: 36px !important;
				flex: 0 0 36px;
				text-align: center;
				padding: 0;
				margin: 0;
				border: none !important;
			}
			&__control {
				cursor: pointer;
				display: flex;
				align-items: center;
				text-align: center;
				justify-content: center;
				border-radius: custom(border_radius);
				color: custom(font_color, $color-white);
				border: 1px solid currentColor;
				width: 37px;
				flex: 0 0 37px;
				height: 39px;
				padding: 0;
				margin: 0;
				background-color: transparent;
				&[disabled] {
					opacity: 0.3;
				}
				&:before {
					font-weight: 700;
					font-size: 1.5em;
					line-height: 1;
				}
				&[data-func="dec"] {
					margin-left: 6px;
					&:before {
						content: "-";
					}
				}
				&[data-func="inc"]:before {
					content: "+";
				}
			}
		}
		&__exchange {
			cursor: pointer;
			display: flex;
			justify-content: center;
			align-items: center;
			background-color: custom(button_color);
			color: custom(font_color_authorization_buttons);
			width: 56px;
			flex: 0 0 56px;
			height: 44px;
			border: none;
			outline: none;
			border-radius: custom(border_radius);
			z-index: 2;
			svg {
				position: relative;
				display: block;
				width: 20px;
				height: 20px;
				transform: rotate(90deg);
			}
		}
		&__date {
			display: flex;
			align-items: center;
			margin-top: 15px;
			border-radius: custom(border_radius);
			border: 1px solid custom(font_color);
			width: 100%;
			flex: 0 0 100%;
			min-height: 45px;
			.forDate {
				position: relative;
				width: 100%;
				flex: 1 0 0%;
				.additImageBlock {
					right: auto;
					left: 0;
					padding-right: 0;
					padding-left: 10px;
				}
			}
			.forTime {
				position: relative;
				width: 50%;
				flex: 0 0 50%;
				.additImageBlock {
					right: 0px;
				}
			}
			.additImageBlock {
				color: custom(font_color);
				display: flex;
				flex-direction: column;
				justify-content: center;
				.icon {
					display: block;
					width: 20px;
					height: 20px;
				}
			}

			input[type="text"],
			input[type="date"],
			input[type="time"] {
				border: none;
				border-radius: 0;
			}

			input[type="time"] {
				text-align: right;
				padding-right: 40px;
			}

			input[type="text"],
			input[type="date"] {
				padding-left: 40px;
			}
		}
		&-select-dropdown {
			&.select2-dropdown {
				color: custom(font_color);
				border-bottom-left-radius: custom(border_radius);
				border-bottom-right-radius: custom(border_radius);
				border-color: custom(font_color);
				background-color: custom(background_color);
				.select2-search--dropdown .select2-search__field {
					border-color: custom(font_color);
					background-color: custom(background_color);
					outline: none !important;
				}
				.select2-results__option--selectable {
					background-color: transparent;
					&.select2-results__option--highlighted {
						background-color: custom(button_color);
						color: custom(font_color_authorization_buttons);
					}
					&.select2-results__option--selected {
						display: none;
					}
				}
			}
		}
	}
	&-status {
		display: flex;
		width: 100%;
		&__item {
			border: 1px solid custom(font_color, $color-white);
			border-radius: 3px;
			width: 100%;
			flex: 1 0 0%;
			padding: 20px;
			text-align: center;
			& + & {
				margin-left: 12px;
			}
		}
	}
	&-submit {
		margin-top: 15px;
		@include media-breakpoint-up(md) {
			display: flex;
			justify-content: center;
		}
	}
}
